import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useDropzone} from 'react-dropzone';
import { useState } from 'react';
import LoadingCard from './LoadingCard';
import { imageSearch } from '../requests';
import SearchResultCard from './SearchResultCard';
import InfiniteScroll from 'react-infinite-scroll-component';




function Search() {
    const [currentPage, setCurrentPage] = useState(1);
    const [searchImage, setSearchImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
  
    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            var file = acceptedFiles[0];
            if(file){
                var reader = new FileReader();
  
                reader.onload = function(e){
                    setSearchImage(() => e.target.result);
                    setLoading(true);
                    runSearch(e.target.result, true);
                }
  
                reader.readAsDataURL(file);
            }
        }
    });
  
  
    function handleSearchResults(results, page, resetResults) {
      if (resetResults === true) {
        setSearchResults(results);
        setCurrentPage(2);
      } else {
        setSearchResults([...searchResults, ...results]);
      }
      setLoading(false);
    }
  
  
    function runSearch(image, resetResults) {
        var fd = {}
        fd['images'] = JSON.stringify([image]);
        fd['page'] = currentPage;
        setCurrentPage((currentPage) => (currentPage + 1));
  
        imageSearch(fd, 
            (data) => {
                handleSearchResults(data.results, 1, resetResults)
            },
            (data) => {
                console.log('failed')
            }
        )
  
    }
  
    function rerunSearch() {
      runSearch(searchImage, false);
    }
  
  
    return (
      <div>
        <Container fluid style={{backgroundColor: 'rgb(220, 220, 220)', minHeight: '100vh'}}>
          <Row>
            <Col className="g-0" xs={12} sm={12} md={12} lg={4} xl={4}>
  
  
              <div style={{textAlign: 'center', paddingTop: '10vh'}} {...getRootProps()}>
                <img src={searchImage ? searchImage : "./placeholder.jpg"} alt="logo" style={{width: '80%'}} className='mild-neumorphic-card'/>
                <input {...getInputProps()} />
              </div>
            </Col>
            <Col className="g-0">
              {
                searchResults.length > 0 && !loading &&
                <div style={{paddingLeft: '2vh', paddingRight: '2vh', paddingTop: '10vh', paddingBottom: '10vh'}}>
                      <InfiniteScroll
                      dataLength={searchResults.length}
                      next={rerunSearch}
                      hasMore={true}
                      loader={
                        <div hidden={!loading} style={{paddingLeft: '2vh', paddingRight: '2vh', paddingTop: '10vh', paddingBottom: '10vh'}}>
                          <LoadingCard />
                          <LoadingCard />
                          <LoadingCard />
                          <LoadingCard />
                          <LoadingCard />
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                    >
                      {
                        searchResults.map((boat, index) => {
                          return (
                            <SearchResultCard boat={boat} key={index} />
                          )
                        })
                      }
                    </InfiniteScroll>
                  
                    
                </div>
              }
              
              <div hidden={!loading} style={{paddingLeft: '2vh', paddingRight: '2vh', paddingTop: '10vh', paddingBottom: '10vh'}}>
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
                <LoadingCard />
              </div>
            
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col className="g-0">
            <div style={{
                backgroundColor: 'rgb(0,0,40)',
                width: '100%', 
                textAlign: 'left',
                height: '15vh',
              }}>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
}

export default Search;