import React from 'react';
import './App.css';
import Search from './pages/Search';
import Details from './pages/Details';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useState } from 'react';


function App() {

  const [currentPage, setCurrentPage] = useState('search');

  return (
    <div>
      <div>
        <Container fluid>
          <Row>
            <Col className="g-0">
              <div style={{
                backgroundColor: 'rgb(0,0,40)',
                width: '100%', 
                textAlign: 'left',
                height: '15vh',
              }}>
                <img src="./rightboat-logo.png" alt="logo" style={{height: '8vh', marginTop: '2.5vh', marginLeft: '5vw'}}/>
                <div style={{
                  backgroundColor: '#60bbff',
                  width: '100%', 
                  textAlign: 'left',
                  height: '2vh',
                  marginTop: '2.5vh'
                }}>

                </div>
              </div>
            </Col>
          </Row>
          <Row style={{backgroundColor: 'rgb(220, 220, 220)'}}>
            <Col>
              <div style={{textAlign: 'center', width:'100%', marginTop: '30px'}}>
                <button style={{padding: '5px', width:'140px', border:'none', fontSize: 20, backgroundColor: currentPage === 'search' ? '#60bbff' : 'rgb(200, 200, 200)'}} onClick={() => {setCurrentPage('search')}}>Search</button>
                <button style={{padding: '5px', width:'140px', border:'none', fontSize: 20, backgroundColor: currentPage === 'data' ? '#60bbff' : 'rgb(200, 200, 200)'}} onClick={() => {setCurrentPage('data')}}>Data API</button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div hidden={currentPage === 'search' ? false : true}>
        <Search />
      </div>
      <div hidden={currentPage === 'data' ? false : true}>
        <Details />
      </div>
    </div>
  );
}

export default App;
