
const baseApiUrl = 'https://rightboat.wfyb.app'



export const RequestWithBody = (url, method, body, successCallback, failedCallback) => {
    fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(data => {
        if (data.success === true) {
            successCallback(data)
        } else {
            failedCallback(data)
        }
    })
}


export const imageSearch = (fd, successCallback, failedCallback) => {
    return RequestWithBody(
        `${baseApiUrl}/image-search`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


export const modelsFromImage = (fd, successCallback, failedCallback) => {
    return RequestWithBody(
        `${baseApiUrl}/models-from-image`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


export const makeCompletion = (fd, successCallback, failedCallback) => {
    return RequestWithBody(
        `${baseApiUrl}/make-completion`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


export const modelCompletion = (fd, successCallback, failedCallback) => {
    return RequestWithBody(
        `${baseApiUrl}/model-completion`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const modelDetails = (fd, successCallback, failedCallback) => {
    return RequestWithBody(
        `${baseApiUrl}/model-details`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


export const priceAggregation = (fd, successCallback, failedCallback) => {
    return RequestWithBody(
        `${baseApiUrl}/make-model-price-aggregation`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}