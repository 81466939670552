import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';





function SearchResultCard(props) {


    function getCurrencySymbol(currency) {
        if (currency === 'EUR') {
            return '€';
        } else if (currency === 'GBP') {
            return '£';
        } else {
            return '$';
        }
    }


    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <a href={props.boat.url} target='_blank' rel="noreferrer" style={{textDecoration: 'none'}}>
            <Container fluid style={{marginBottom: '40px'}}>
                <Row>
                    <Col sm={12} md={12} lg={6} xl={6} className="g-0">
                        <div style={{
                            backgroundColor: 'rgb(200, 200, 200)',
                            backgroundImage: `url(${props.boat.image_url})`, 
                            width: '100%', 
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            textAlign: 'left',
                            height: '40vh',
                        }}>

                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6} className="g-0">
                        <div style={{backgroundColor: '#60bbff', padding: '15px', height: '40vh'}}>
                            <h4 style={{color:'white'}}>{props.boat.title}</h4>
                            {
                                props.boat.price 
                                ? 
                                <h5 style={{color: 'white'}}>
                                    {getCurrencySymbol(props.boat.currency)}{formatNumber(props.boat.price)} {props.boat.currency}
                                </h5> 
                                : 
                                <h5 style={{color: 'white'}}>Price not listed</h5>
                            }
                            <div style={{backgroundColor: 'white', height: '2px', width: '100%'}}></div>
                            <Row style={{color: 'white', marginTop: '3vh'}}>
                                <Col>
                                    <h5>Year</h5>
                                    {
                                        props.boat.year
                                        ?
                                        <h6>{props.boat.year}</h6>
                                        :
                                        <h6>Year not listed</h6>
                                    }
                                </Col>
                                <Col>
                                    <h5>Length</h5>
                                    {
                                        props.boat.length
                                        ?
                                        <h6>{Math.round(props.boat.length * 10)/10} m ({parseInt(props.boat.length * 3.281)} ft)</h6>
                                        :
                                        <h6>Length not listed</h6>
                                    }
                                </Col>
                                <Col>
                                    <h5>Country</h5>
                                    {
                                        props.boat.country
                                        ?
                                        <h6>{props.boat.country}</h6>
                                        :
                                        <h6>Country not listed</h6>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </a>
    );
}

export default SearchResultCard;