import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

export default function LoadingCard() {
  return (
    <Container fluid className='shimmerBG' style={{marginBottom: '40px'}} >
            <Row>
                <Col sm={12} md={12} lg={6} xl={6} className="g-0">
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Image fluid src={'/new_logo_trans.png'} style={{height: '200px', marginTop: '100px', marginLeft: 'auto', marginRight: 'auto'}} />
                    </div>
                </Col>
                <Col sm={12} md={12} lg={6} xl={6} style={{backgroundColor: 'rgb(230, 230, 230)', padding: '15px', color: 'white', height: '40vh'}}>
                    <div className='shimmerBG' style={{backgroundColor:'rgb(200,200,200', height: '30px', width: '70%', marginTop: '30px', borderRadius: '15px'}}></div>
                    <Row>
                        <Col>
                            <div className='shimmerBG' style={{backgroundColor:'rgb(200,200,200', height: '20px', width: '70%', marginTop: '30px', borderRadius: '10px'}}></div>
                            <div className='shimmerBG' style={{backgroundColor:'rgb(200,200,200', height: '20px', width: '70%', marginTop: '30px', borderRadius: '10px'}}></div>
                        </Col>
                        <Col>
                            <div className='shimmerBG' style={{backgroundColor:'rgb(200,200,200', height: '20px', width: '70%', marginTop: '30px', borderRadius: '10px'}}></div>
                        </Col>
                    </Row>
                    <div>
                        <div className='shimmerBG' style={{backgroundColor:'rgb(200,200,200', height: '14px', width: '80%', marginTop: '30px', borderRadius: '7px'}}></div>
                        <div className='shimmerBG' style={{backgroundColor:'rgb(200,200,200', height: '14px', width: '80%', marginTop: '15px', borderRadius: '7px'}}></div>
                        <div className='shimmerBG' style={{backgroundColor:'rgb(200,200,200', height: '14px', width: '60%', marginTop: '15px', borderRadius: '7px'}}></div>
                    </div>
                </Col>
            </Row>
        </Container> 
  )
}
