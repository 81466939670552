import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useDropzone} from 'react-dropzone';
import { useState } from 'react';
import { modelsFromImage, makeCompletion, modelCompletion, modelDetails, priceAggregation } from '../requests';
import Select from 'react-select'

function Details(props) {

    const [searchImage, setSearchImage] = useState(null);
    const [imageResultsLoading, setImageResultsLoading] = useState(false);
    const [imageResults, setImageResults] = useState([]);

    const [modelData, setModelData] = useState(null);
    const [priceData, setPriceData] = useState(null);

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            var file = acceptedFiles[0];
            if(file){
                var reader = new FileReader();
  
                reader.onload = function(e){
                    setImageResultsLoading(true);
                    setImageResults([]);
                    setSearchImage(() => e.target.result);
                    runImageSearch(e.target.result);

                }
  
                reader.readAsDataURL(file);
            }
        }
    });


    function runImageSearch(image) {
        var fd = {}
        fd['images'] = JSON.stringify([image]); 
        
        modelsFromImage(fd, 
            (data) => {
                setImageResults(data.results);
                setImageResultsLoading(false);
            },
            (data) => {
                console.log('failed')
            }
        )
    }

    function handleModelClick(result) {
        setModelData(result);
        setImageResults([]);
        setImageResultsLoading(false);
        setSelectedMake({value: result.make, label: result.make});
        setSelectedModel({value: result.model, label: result.model});
        handleMakeModelPriceAggregation(result.make, result.model);
    }


    const [selectedMake, setSelectedMake] = useState(null);
    const [selectedModel, setSelectedModel] = useState(null);

    const [makeOptions, setMakeOptions] = useState([]);
    const [modelOptions, setModelOptions] = useState([]);



    function handleMakeCompletion(e) {
        var fd = {}
        fd['make_initial'] = e.target.value;
        
        console.log(e.target.value)
        makeCompletion(fd,
            (data) => {
                var options = [];
                data.results.forEach((result) => {
                    options.push({value: result, label: result})
                })
                setMakeOptions(options);
            },
            (data) => {
                console.log('failed')
            }
        )
    }

    function handleModelCompletion(e) {
        var fd = {}
        fd['make'] = selectedMake.value;
        fd['model_initial'] = e.target.value;
        
        console.log(e.target.value)
        modelCompletion(fd,
            (data) => {
                var options = [];
                data.results.forEach((result) => {
                    options.push({value: result, label: result})
                })
                setModelOptions(options);
            },
            (data) => {
                console.log('failed')
                console.log(data)
            }
        )
    }


    function handleGetModelDetails() {
        var fd = {}
        fd['make'] = selectedMake.value;
        fd['model'] = selectedModel.value;
        
        console.log(selectedMake.value)
        console.log(selectedModel.value)
        modelDetails(fd,
            (data) => {
                handleModelClick(data.results)
            },
            (data) => {
                console.log('failed')
                console.log(data)
            }
        )
    }


    function handleMakeModelPriceAggregation(make, model) {
        var fd = {}
        fd['make'] = make;
        fd['model'] = model;
        
        priceAggregation(fd,
            (data) => {
                setPriceData(data.results)
            },
            (data) => {
                console.log('failed')
                console.log(data)
            }
        )
    }

    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    return (
        <Container fluid style={{backgroundColor: 'rgb(220, 220, 220)', minHeight: '100vh'}}>
            <Row>
                <Col className="g-0" xs={12} sm={12} md={12} lg={4} xl={4}>
                    <div style={{textAlign: 'center', paddingTop: '10vh'}} {...getRootProps()}>
                        <img src={searchImage ? searchImage : "./placeholder.jpg"} alt="logo" style={{width: '80%'}} className='mild-neumorphic-card'/>
                        <input {...getInputProps()} />
                    </div>
                </Col>
                <Col className="g-0" xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Container fluid>
                        <Row style={{paddingTop: '10vh'}}>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                <p>Make</p>
                                <Select  
                                    value={selectedMake}
                                    onKeyDown={(e) => {handleMakeCompletion(e)}} 
                                    onChange={(e) => {setSelectedMake(e)}} 
                                    options={makeOptions} 
                                />
                            </Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4}>
                                <p>Model</p>
                                <Select 
                                    value={selectedModel}
                                    isDisabled={selectedMake ? false : true}  
                                    onKeyDown={(e) => {handleModelCompletion(e)}} 
                                    onChange={(e) => {setSelectedModel(e)}} 
                                    options={modelOptions} 
                                />
                            </Col>
                            <Col xs={6} sm={6} md={4} lg={4} xl={4} style={{paddingTop: '25px'}}>
                                <button onClick={() => {handleGetModelDetails()}} style={{padding: '5px', width: '100%', border:'none', fontSize: 18, backgroundColor: '#60bbff', marginTop: '2vh'}}>Get Data</button>
                            </Col>
                        </Row>
                        <Row hidden={imageResultsLoading ? false : true} style={{paddingTop: '40px'}}>
                            <h4>Loading potential models...</h4>
                        </Row>
                        <Row hidden={imageResults.length === 0 ? true : false} style={{paddingTop: '40px'}}>
                            {
                                imageResults.slice(0, 12).map((result, index) => {
                                    return (
                                        <Col xs={6} sm={6} md={4} lg={4} xl={4} key={index}>
                                            <div onClick={() => {handleModelClick(result)}} className='dropdown-item' style={{textAlign: 'center', padding: '1vh', margin: '5px'}}>
                                                <p>{result.make} {result.model}</p>
                                            </div>
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </Col>
            </Row>
            {
                modelData &&
                    <Row >
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div style={{padding: '30px'}}>
                                <h5>Make: {modelData.make}</h5>
                                <h5>Model: {modelData.model}</h5>
                                <h4 style={{marginTop: '20px'}}><b>Predicted Price Range</b></h4>
                                <h5>Low: {priceData ? '$' : ''}{priceData ? formatNumber(parseInt(priceData.min)) : ''} USD</h5>
                                <h5>High: {priceData ? '$' : ''}{priceData ? formatNumber(parseInt(priceData.max)) : ''} USD</h5>
                                <h5>Average: {priceData ? '$' : ''}{priceData ? formatNumber(parseInt(priceData.mean)) : ''} USD</h5>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div style={{padding: '30px'}}>
                                <h4><b>Dimensions</b></h4>
                                <h5>Length Overall: {modelData.dimensions.length_overall} m</h5>
                                <h5>Beam: {modelData.dimensions.beam} m</h5>
                                <h5>Draft: {modelData.dimensions.draft} m</h5>
                                <h5>Displacement: {modelData.dimensions.displacement} kg</h5>
                                <h5>Ballast: {modelData.dimensions.ballast} kg</h5>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                            <div style={{padding: '30px'}}>
                                <h5>Cabins: {modelData.interior_layout.cabins}</h5>
                                <h5>Berths: {modelData.interior_layout.berths}</h5>
                                <h5>Heads: {modelData.interior_layout.heads}</h5>
                            </div>
                        </Col>
                    </Row>
            }
        </Container>
    );
}

export default Details;